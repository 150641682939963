.App {
  text-align: center;
}

.messages {
  display: flex;
  justify-content: center;
}

.error {
  display: block;
  background-color: red;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 50px;
  padding: 5px;
}

.success {
  display: block;
  background-color: lightblue;
  color: black;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 50px;
  padding: 5px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.live_chat{
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
}
.live_chat:hover{
  background-color:#48cb57;
  color:#ffffff;
  border-radius:10px;
  transition: 0.3s;
}
/* Heading */
.ltn__footer-area .live_chat h5{
  background-color:#48cb57;
  background-position-x:0%;
  background-position-y:0%;
  border-radius:10px;
  min-width:1px;
  max-width:100px;
  min-height:auto;
  max-height:auto;
  height:25px;
  width:100px;
  text-align:center;
  letter-spacing:0.6px;
  line-height:1.5em;
  color:#ffffff;
}
/* .ltn__footer-area .live_chat h5:hover{
  background-color:#cb4848;
  color:#ffffff;
}
  */
.whatsapp-logo{
  width: 64px;
  height: 64px;
  
}
/* Image */
.live_chat a img{
  position:relative;
  left:20px;
  bottom:12px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

